import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const opts = {
    theme: {
      themes: {
        light: {
          primary: '#fff', //white
          secondary: '#273343', // blue
          terciary: '#14131c', // casi black
          accent: '#dcdcdc', // grey
          accent2: '5e5d61', // dimgrey
          success: '#4caf50',
          info: '#42a5f5',
          warning: '#ffb74d',
          error: '#b71c1c',
        },
        dark: {
          primary: '#3f51b5',
          secondary: '#b0bec5',
          accent: '#8c9eff',
          success: '',
          info: '',
          warning: '',
          error: '#b71c1c',
        }
      },
    },
  }

export default new Vuetify(opts);
