<template>
  <v-app>
    <!-- Toolbar -->
    <!-- <v-row> -->
    <app-navigation style="z-index: 1;"></app-navigation>
    <router-view />
    <!-- </v-row> -->
    <v-row class="d-flex align-end justify-end mr-0" v-if="!$store.state.resultsVisible">
      <p class="mb-0 mr-1 caption">{{ version }}</p>
    </v-row>

    <!-- <app-footer v-if="!$store.state.resultsVisible"></app-footer> -->
  </v-app>
</template>

<script>
import AppNavigation from '@/components/AppNavigation'
// import AppFooter from '@/components/Footer'

export default {
  name: 'App',
  components: {
    AppNavigation
    // AppFooter
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION_NUMBER
    }
  },
  created() {
    window.onbeforeunload = function() {
      this.$store.commit('resetStore')
    }
  }
};
</script>

<style lang="css">

</style>
