import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)
Vue.prototype.axios = axios

function initialState() {
  return {
    machines: [],
    currentMachineUuid: '',
    currentMachine: {},
    currentDocument:'',
    currentImage:'',
    isLoading: false,
    selectedPlan: 0,
    resultsVisible: false
  }
}

export default new Vuex.Store({
  state: initialState,
  getters: {
    selectedPlan (state) {
      return state.selectedPlan;
    },
    getMachinesResults(state) {
      if(state.machines.machines === undefined) {
        return state.machines.machines;
      }
      for(var a = 0; a < state.machines.machines.length; a++) {
        state.machines.machines[a].imgurl = `${process.env.VUE_APP_BASE_URI}${'/getimage/'}${state.machines.machines[a].imageuuid}`
        if(state.machines.machines[a].imageuuid === '') {
          // state.machines.machines[a].imgurl="https://ploou-produccion.appspot.com/getimage/86964f76-fb15-4cc2-a57e-d8438eda5a22"
          state.machines.machines[a].imgurl = 'https://www.ploou.com/logoploou.png'
        }
        state.machines.machines[a].machinerate = 5
      }
      return state.machines.machines
    }
  },
  mutations: {
    setMachines(state, payload) {
      state.machines = payload
    },
    loading (state, value) {
      state.isLoading = value
    },
    setCurrentMachine (state, value) {
      state.currentMachine = value
    },
    setCurrentMachineUuid (state, value) {
      state.currentMachineUuid = value
    },
    setCurrentDocument (state, value) {
      state.currentDocument = value
    },
    setCurrentImage (state, value) {
      state.currentImage = value
    },
    setSelectedPlan (state, value) {
      state.selectedPlan = value
    },
    isResultsVisible (state, visible) {
      state.resultsVisible = visible
    },
    resetStore (state) {
      const initState = initialState()
      Object.keys(initState).forEach(key => {
        state[key] = initState[key]
      })
    },
  },
  actions: {
    async getMachines({ commit }, plan) {
      commit('loading', true)
      try {    
        let response = await axios.post(`${process.env.VUE_APP_BASE_URI}${'/filtermachinesbyspecs'}`, { filters: { machinetype: plan } });
        commit('loading', false)
        commit('setMachines', response.data);
        commit('setSelectedPlan', plan)
      } catch (error) {
        commit('loading', false)
        commit('setMachines', []);
      }
    },
    async getFilteredMachines({ commit }, filter) {
      try {
        commit('loading', true)
        let response = await axios.post(`${process.env.VUE_APP_BASE_URI}${'/filtermachinesbyspecs'}`, { filters:filter });
        commit('loading', false)
        commit('setMachines', response.data)
        router.push('/results')
      } catch (error) {
        commit('loading', false)
        commit('setMachines', []);
      }
    },
    async getCurrentMachine({ commit }, uuid) {
      try {     
        let response = await axios.get(`${process.env.VUE_APP_BASE_URI}${'/getmachinebyuuid/'}${uuid}`)
        commit('setCurrentMachine', response.data.machinedata);
      } catch (error) {
        commit('setCurrentMachine', {});
      }
    }
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })]
});