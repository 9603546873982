<template>
  <!-- Primera pantalla con imagen de fondo y las tarjetas de buscar máquina -->
  <span>
    <home-hero></home-hero>
  </span>
  <!--  <home-details></home-details> -->
  <!--  <home-plans></home-plans>  -->
</template>

<script>
import HomeHero from '@/components/HomeHero';
// import HomeDetails from '@/components/HomeDetails';
// import HomePlans from '@/components/HomePlans';

export default {
  name: 'home',
  components: {
    HomeHero,
    // HomeDetails,
    // HomePlans
  }
};
</script>

<style lang="css" scoped>

</style>