<template>
  <v-toolbar maxHeight="64px" color="primary" :elevation="0">
    <v-toolbar-title>
      <a href="https://www.ploou.com" target="_blank" style="margin-left: 30px;">
        <img src="@/assets/logo.svg" height="36"/>
      </a>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <a href="https://www.ploou.com" target="_blank" style="margin-left: 20px;">
      <span class="secondary--text">INICIO</span>
      </a>
      <a style="margin-left: 20px;" class="cursor-def"> <!-- href="https://www.ploou.com/ploouwebapp/dist" target="_blank" -->
      <span class="secondary--text">BUSCAR EQUIPO</span>
      </a>
      <a href="https://www.ploou.com/blog" target="_blank" style="margin-left: 20px;">
      <span class="secondary--text">BLOG</span>
      </a>
      <a href="https://www.ploou.com/plouu-en-los-medios/" target="_blank" style="margin-left: 20px;">
      <span class="secondary--text">MEDIOS</span>
      </a>
      <a href="https://www.ploou.com/app-multimedia-agricultura-john-deere/" target="_blank" style="margin-left: 20px;">
      <span class="secondary--text">MULTIMEDIA</span>
      </a>
      <!-- <a href="https://www.ploou.com" target="_blank" style="margin-left: 50px;">
      <span class="secondary--text">CONTACTO</span>
      </a> -->
      <v-spacer></v-spacer>
      <v-btn color="secondary" :ripple="false" outlined href="https://www.ploou.com/plooupremium/web" target="_blank" style="margin-right: 30px;">Acceso Usuarios</v-btn>
    </template>
    <v-menu top :close-on-content-click="true" v-else>
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon v-bind="attrs" v-on="on" ></v-app-bar-nav-icon>
      </template>
      <v-list style="cursor: pointer;">
        <v-list-item>
          <v-list-item-title @click="goToHome">INICIO</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title @click="goToSearchMachine">BUSCAR EQUIPO</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title @click="goToBlog">BLOG</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title @click="goToMedia">MEDIOS</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title @click="goToMultimedia">MULTIMEDIA</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-toolbar>
</template>

<script>
export default {
  name: 'appnavigation',
  data() {
    return {
      appTitle: 'PLOOU'
    }
  },
  methods: {
    goToHome() {
      window.open('https://www.ploou.com', '_blank')
    },
    // goToSearchMachine() {
    //   window.open('https://www.ploou.com/ploouwebapp/dist', '_blank')
    // },
    goToBlog() {
      window.open('https://www.ploou.com/blog', '_blank')
    },
    goToMedia() {
      window.open('https://www.ploou.com/plouu-en-los-medios/', '_blank')
    },
    goToMultimedia() {
      window.open('https://www.ploou.com/app-multimedia-agricultura-john-deere/', '_blank')
    }
  }
}
</script>

<style lang="css" scoped>
a { 
  text-decoration: none; 
}
.theme--light.v-btn--active:hover::before, .theme--light.v-btn--active::before {
  opacity: 0;
}
.cursor-def {
  cursor: default;
}
</style>