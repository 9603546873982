<template>
  <v-container fluid fill-height class="home-hero" style="max-height: 100vh;">
    <span class="bg" :style="{ backgroundImage: 'url(\'' + image + '\')', backgroundPosition: position }"></span>
    <v-row align="center" class="justify-center pt-16 pb-16" cols="12">
      <v-col lg="1" md="1" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
      <v-col lg="10" md="10" sm="12" xs="12" :class="{'myTextContainer': $vuetify.breakpoint.mdAndUp, 'myTextContainerMobile': $vuetify.breakpoint.mdAndDown }">
        <span class="primary--text text-center display-1 shadow">EQUIPOS PARA ALQUILAR</span>
        <br><br>
        <span class="primary--text text-center myText text-h5 shadow">PLOOU te lo pone más fácil que nunca: encuentra el equipo que mejor se adapte a tus necesidades y presupuesto con la calidad y garantía de las mejores marcas y ¡empieza a ahorrar!</span>
        <br><br>
        <span class="primary--text text-center display-1 shadow">ENCUENTRA YA TU MÁQUINA</span>
      </v-col>
      <v-col lg="1" md="1" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
    </v-row>
    <!-- Las rutas hijas, que son las categorías, búsqueda y resultado de la búsqueda -->  
    <router-view />
  </v-container>
</template>

<script>
export default {
  name: 'HomeHero',
  data() {
    return {
      backgroundImages: [
        { src: require('../assets/stihl-buscador-1.jpg') },
        { src: require('../assets/stihl-buscador-2.jpg') },
        { src: require('../assets/stihl-buscador-3.jpg') },
        { src: require('../assets/stihl-buscador-4.jpg') },
        { src: require('../assets/john-deere-buscador-1.jpg') },
        { src: require('../assets/john-deere-buscador-2.jpg') },
        { src: require('../assets/john-deere-buscador-3.jpg') },
        { src: require('../assets/john-deere-buscador-4.jpg') },
        { src: require('../assets/john-deere-buscador-5.jpg') },
        { src: require('../assets/back-buscador.jpg') }
      ],
      image: '',
      selectedImage: 0,
      totalImages: -1,
      position: ''
    }
  },
  mounted() {
    this.image = this.backgroundImages[0].src
    this.totalImages = this.backgroundImages.length
    this.$store.commit('resetStore')
    const $this = this
    setInterval(() => {
      if ($this.selectedImage === $this.totalImages - 1) {
        $this.selectedImage = 0
        $this.position = ''
      } else {
        $this.selectedImage += 1
        $this.position = 'center'
      }
      $this.image = $this.backgroundImages[$this.selectedImage].src
    }, 10000)
  }
}
</script>

<style scoped>
.bg {
  width: 100%;
  height: 75%;
  position: absolute;
  margin-top: 3%;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1.0);
  -webkit-transition: background-image 0.1s ease-in-out;
  transition: background-image 0.1s ease-in-out;
}
.theme--light.v-btn--active:hover::before, .theme--light.v-btn--active::before {
  opacity: 0;
}
.myTextContainer {
  z-index: 1; 
  padding: 0;
}
.myTextContainerMobile {
  z-index: 1; 
  padding: 20px;
}
.myText {
  font-size: 1.2rem;
}
.shadow {
  text-shadow: 0 0 0.4em black, 0 0 0.4em black, 0 0 0.4em black
}
/* @keyframes cf3FadeInOut {
  0% {
    opacity:1;
  }
  45% {
    opacity:1;
  }
  55% {
    opacity:0;
  }
  100% {
    opacity:0;
  }
}

#cf4a span {
  animation-delay: 6s;
} */

</style>