import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    component: Home,
    children: [
      // {
      //   path: '',
      //   component: () => import('@/components/CategoryFilter')
      // },
      {
        path: '',
        component: () => import('@/components/Search')
      },
      {
        name: 'results',
        path: '/results',
        component: () => import('@/components/Results')
      }
    ]
  },
  {
    path: '/details/:id',
    name: 'details',
    component: () => import('@/components/Details')
  }
]

const router = new VueRouter({
  routes
})

export default router
